import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TeamMemberRole } from 'models';
import { map } from 'rxjs';
import { Session } from './session.service';
import { EMPTY } from './utils';

export const playerRoleGuard: CanActivateFn = () => {
    const session = inject(Session);
    return session.user.teamMemberRole === TeamMemberRole.Player;
};

export const isSystemAdministratorGuard: CanActivateFn = () => {
    const session = inject(Session);
    return session.user.isInRole('System administrator');
};

export const authorizedGuard: CanActivateFn = () => {
    const session = inject(Session);
    const router = inject(Router);
    return session.initialize().pipe(
        map((res) => {
            if (!res) {
                router.navigate(['/', 'account', 'login']).catch(EMPTY);
                return false;
            }
            if (!session.user.onboardingStatus.isCompleted) {
                router.navigate(['/', 'account', 'create', '1']).catch(EMPTY);
                return false;
            }
            return true;
        }),
    );
};
